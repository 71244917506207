import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Button } from '@material-ui/core'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Field from './formik/Field'
import contactYup from '../lib/schemas/contactForm'

const useStyles = makeStyles(theme => ({
    fields: {
        display                        : 'flex',
        flex                           : 1,
        flexDirection                  : 'column',
        justifyContent                 : 'center',
        alignItems                     : 'stretch',
        margin                         : '0 2em',
        [theme.breakpoints.down('sm')] : {
            margin: '1em'
        }
    },
    inputRoot: {
        fontWeight              : 600,
        height                  : '100%',
        color                   : 'var(--main-color)',
        backgroundColor         : '#fff',
        margin                  : '5px',
        '& .MuiInputBase-input' : {
            fontSize: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiInputBase-input': {
                fontSize: '0.8rem'
            }
        }
    },
    baseInput: {
        paddingLeft     : theme.spacing(1),
        paddingTop      : theme.spacing(2),
        paddingBottom   : theme.spacing(2)
    },
    sendButton: {
        height          : '60px',
        width           : '80%',
        backgroundColor : 'var(--main-color)',
        color           : 'var(--white)',
        fontSize        : '2rem',
        '&:hover'           : {
            backgroundColor : 'var(--white)',
            color           : 'var(--main-color)'
        },
        '&:disabled': {
            backgroundColor : 'var(--dark-gray)',
            color           : 'var(--light-gray)'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop   : -30,
            height      : '45px',
            width       : '80%',
            fontSize    : '1rem'
        }
    }
}))

export default function TopForm ({ _handleOnSubmit, submittingForm }) {
    const classes = useStyles()
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const xSmallScreen = useMediaQuery(theme.breakpoints.down('325'))
    const rows = smallScreen ? 2 : 5
    const multiline = !xSmallScreen
    return (
        <Formik
            onSubmit={ _handleOnSubmit }
            initialValues={{ name: '', email: '', phone: '', message: '' }}
            validateOnChange
            validateOnBlur
            validationSchema={ contactYup }
        >
            {
                ({
                    handleSubmit,
                    handleChange
                }) => (
                    <form onSubmit={ handleSubmit }>
                        <Box className={ classes.fields }>
                            <Field
                                onChange={ handleChange }
                                fieldProps={{
                                    name        : 'name',
                                    type        : 'text',
                                    placeholder : 'Nombre *',
                                    label       : 'Nombre'
                                }}
                                childProps={{
                                    className   : classes.field,
                                    InputProps  : {
                                        className           : classes.inputRoot,
                                        classes             : {
                                            input: classes.baseInput
                                        }
                                    }
                                }}
                            />
                            <Field
                                onChange={ handleChange }
                                fieldProps={{
                                    name        : 'email',
                                    type        : 'email',
                                    placeholder : 'Correo electrónico *',
                                    label       : 'Correo electrónico'
                                }}
                                childProps={{
                                    className   : classes.field,
                                    InputProps  : {
                                        className           : classes.inputRoot,
                                        classes             : {
                                            input: classes.baseInput
                                        }
                                    }
                                }}
                            />
                            <Field
                                onChange={ handleChange }
                                fieldProps={{
                                    name        : 'phone',
                                    type        : 'text',
                                    placeholder : 'Teléfono (10 dígitos) *',
                                    label       : 'Teléfono'
                                }}
                                childProps={{
                                    className   : classes.field,
                                    InputProps  : {
                                        className           : classes.inputRoot,
                                        classes             : {
                                            input: classes.baseInput
                                        }
                                    }
                                }}
                            />
                            <Field
                                onChange={ handleChange }
                                fieldProps={{
                                    name        : 'message',
                                    type        : 'text',
                                    placeholder : 'Escribe tu mensaje...',
                                    label       : 'Mensaje'
                                }}
                                childProps={{
                                    className   : classes.field,
                                    InputProps  : {
                                        className           : classes.inputRoot,
                                        classes             : {
                                            input: classes.baseInput
                                        }
                                    },
                                    multiline,
                                    rows
                                }}
                            />
                        </Box>
                        <Box className={ classes.send } mt={ 4 }>
                            <Button
                                className={ classes.sendButton }
                                type="submit"
                                onClick={ handleSubmit }
                                disabled={ submittingForm }
                            >
                                Enviar
                            </Button>
                        </Box>
                    </form>
                )
            }
        </Formik>
    )
}

TopForm.propTypes = {
    _handleOnSubmit : PropTypes.func.isRequired,
    submittingForm  : PropTypes.bool.isRequired
}
