import React from 'react'
// import { MuiThemeProvider } from '@material-ui/core/styles'

import Landing from './Landing'

const App = () => <Landing />
// <MuiThemeProvider>
// <Landing />
// </MuiThemeProvider>

export default App
