import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Grid, Box, Button } from '@material-ui/core'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Field from './formik/Field'
import contactYup from '../lib/schemas/contactForm'

const useStyles = makeStyles(theme => ({
    field: {
        width                    : '100%',
        // '& .MuiInputLabel-root & .MuiFormLabel-shrink'  : {
        //     color: 'var(--light-gray)'
        // },
        '& .MuiInputLabel-root' : {
            color: 'var(--black)'
        }
    },
    inputRoot: {
        fontWeight              : 600,
        height                  : '100%',
        color                   : 'var(--main-color)',
        backgroundColor         : '#fff',
        margin                  : '5px',
        '& .MuiInputBase-input' : {
            fontSize: '1rem'
        },
        '& .MuiFormLabel-root': {
            color: 'var(--white)'
        }
    },
    baseInput: {
        paddingLeft     : theme.spacing(1),
        paddingTop      : theme.spacing(2),
        paddingBottom   : theme.spacing(2)
    },
    sendButton: {
        height          : '70px',
        width           : '80%',
        backgroundColor : 'var(--black)',
        color           : 'var(--white)',
        fontSize        : '2rem',
        '&:hover'           : {
            backgroundColor : 'var(--white)',
            color           : 'var(--main-color)'
        },
        '&:disabled': {
            backgroundColor : 'var(--dark-gray)',
            color           : 'var(--light-gray)'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop   : -30,
            height      : '45px',
            width       : '80%',
            fontSize    : '1rem'
        }
    }
}))

export default function BottomForm ({ _handleOnSubmit, submittingForm }) {
    const classes = useStyles()
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const rows = smallScreen ? 2 : 5

    return (
        <Formik
            onSubmit={ _handleOnSubmit }
            initialValues={{ name: '', email: '', phone: '', message: '' }}
            validateOnChange
            validateOnBlur
            validationSchema={ contactYup }
        >
            {
                ({
                    handleSubmit,
                    handleChange
                }) => (
                    <form onSubmit={ handleSubmit }>
                        <Grid container>
                            <Grid item md={ 4 } xs={ 12 }>
                                <Field
                                    onChange={ handleChange }
                                    fieldProps={{
                                        name        : 'name',
                                        type        : 'text',
                                        placeholder : 'Nombre *',
                                        label       : 'Nombre'
                                    }}
                                    childProps={{
                                        className   : classes.field,
                                        InputProps  : {
                                            className           : classes.inputRoot,
                                            classes             : {
                                                input: classes.baseInput
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item md={ 4 } xs={ 12 }>
                                <Field
                                    onChange={ handleChange }
                                    fieldProps={{
                                        name        : 'email',
                                        type        : 'email',
                                        placeholder : 'Correo electrónico *',
                                        label       : 'Correo electrónico'
                                    }}
                                    childProps={{
                                        className   : classes.field,
                                        InputProps  : {
                                            className           : classes.inputRoot,
                                            classes             : {
                                                input: classes.baseInput
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item md={ 4 } xs={ 12 }>
                                <Field
                                    onChange={ handleChange }
                                    fieldProps={{
                                        name        : 'phone',
                                        type        : 'text',
                                        placeholder : 'Teléfono (10 dígitos) *',
                                        label       : 'Teléfono'
                                    }}
                                    childProps={{
                                        className   : classes.field,
                                        InputProps  : {
                                            className           : classes.inputRoot,
                                            classes             : {
                                                input: classes.baseInput
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item md={ 12 } className={ classes.field }>
                                <Field
                                    onChange={ handleChange }
                                    fieldProps={{
                                        name        : 'message',
                                        type        : 'text',
                                        placeholder : 'Escribe tu mensaje...',
                                        label       : 'Mensaje'
                                    }}
                                    childProps={{
                                        className   : classes.field,
                                        InputProps  : {
                                            className           : classes.inputRoot,
                                            classes             : {
                                                input: classes.baseInput
                                            }
                                        },
                                        multiline: true,
                                        rows
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Box className={ classes.send } mt={ 4 }>
                            <Button
                                className={ classes.sendButton }
                                type="submit"
                                onClick={ handleSubmit }
                                disabled={ submittingForm }
                            >
                                Enviar
                            </Button>
                        </Box>
                    </form>
                )
            }
        </Formik>
    )
}

BottomForm.propTypes = {
    _handleOnSubmit : PropTypes.func.isRequired,
    submittingForm  : PropTypes.bool.isRequired
}
