/* eslint-disable react/jsx-no-bind */
import React from 'react'
import PropTypes from 'prop-types'
import { SwipeableDrawer, makeStyles, List, ListItem, ListItemText } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    drawer: {
        display         : 'flex',
        flex            : 1,
        backgroundColor : 'var(--main-color)'
    },
    menuText: {
        fontSize    : '3rem',
        color       : 'var(--white)',
        fontFamily  : 'var(--main-font)'
    },
    button: {
        textTransform   : 'none',
        color           : '#fff',
        marginLeft      : '-8px',
        backgroundColor : 'transparent',
        boxShadow       : 'none'
    }
}))

const TemporaryDrawer = ({
    openDrawer,
    toggleDrawer,
    scrollToRef,
    refs
}) => {
    const classes = useStyles()
    const _checkIOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

    return (
        <SwipeableDrawer
            open={ openDrawer }
            onClose={ toggleDrawer }
            onOpen={ toggleDrawer }
            disableBackdropTransition={ !_checkIOS }
            disableDiscovery={ _checkIOS }
            anchor="right"
        >
            <div
                className={ classes.drawer }
                role="presentation"
            >
                <List
                    dense
                >
                    <ListItem
                        button
                        onClick={ () => { toggleDrawer(); scrollToRef(refs.acerca) } }
                    >
                        <ListItemText
                            primary="Acerca"
                            className={ classes.menuText }
                            primaryTypographyProps={{
                                noWrap      : true,
                                variant     : 'body2'
                            }}
                        />
                    </ListItem>
                    <ListItem
                        button
                        onClick={ () => { toggleDrawer(); scrollToRef(refs.rutas) } }
                    >
                        <ListItemText
                            primary="Rutas"
                            className={ classes.menuText }
                            primaryTypographyProps={{
                                noWrap      : true,
                                variant     : 'body2'
                            }}
                        />
                    </ListItem>
                    <ListItem
                        button
                        onClick={ () => { toggleDrawer(); scrollToRef(refs.servicios) } }
                    >
                        <ListItemText
                            primary="Servicios"
                            className={ classes.menuText }
                            fontSize="1rem"
                            primaryTypographyProps={{
                                noWrap      : true,
                                variant     : 'body2'
                            }}
                        />
                    </ListItem>
                    <ListItem
                        button
                        onClick={ () => { toggleDrawer(); scrollToRef(refs.clientes) } }
                    >
                        <ListItemText
                            primary="Clientes"
                            className={ classes.menuText }
                            primaryTypographyProps={{
                                noWrap      : true,
                                variant     : 'body2'
                            }}
                        />
                    </ListItem>
                    <ListItem
                        button
                        onClick={ () => { toggleDrawer(); scrollToRef(refs.contacto) } }
                    >
                        <ListItemText
                            primary="Contacto"
                            className={ classes.menuText }
                            primaryTypographyProps={{
                                noWrap      : true,
                                variant     : 'body2'
                            }}
                        />
                    </ListItem>
                </List>
            </div>
        </SwipeableDrawer>
    )
}

TemporaryDrawer.propTypes = {
    openDrawer       : PropTypes.bool.isRequired,
    toggleDrawer     : PropTypes.func.isRequired,
    scrollToRef      : PropTypes.func.isRequired,
    refs             : PropTypes.object.isRequired
}

export default TemporaryDrawer

