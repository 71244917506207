/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Box, Hidden, Button, IconButton } from '@material-ui/core/'
import MenuIcon from '@material-ui/icons/Menu'
import PropTypes from 'prop-types'

import logo from '../assets/images/logo.png'
// import llamanos from '../assets/images/llamanos.png'
import TemporaryDrawer from './TemporaryDrawer'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow : 1,
        zIndex   : 99
    },
    menu: {
        color       : 'var(--white)',
        fontFamily  : 'var(--main-font-bold)',
        fontSize    : '1rem'
    },
    title: {
        flexGrow: 1
    },
    topnav: {
        backgroundColor : 'var(--main-color)',
        height          : '4em'
    },
    logo: {
        width                          : 230,
        position                       : 'absolute',
        left                           : '20vw',
        backgroundColor                : 'var(--white)',
        borderBottomLeftRadius         : 20,
        borderBottomRightRadius        : 20,
        padding                        : '1.6em 1em 0.7em 1em',
        [theme.breakpoints.down('lg')] : {
            left: '8vw'
        },
        [theme.breakpoints.down('970')]: {
            left: '10vw'
        },
        [theme.breakpoints.down('600')]: {
            left     : '25vw',
            width    : 180
        },
        [theme.breakpoints.down('325')]: {
            width   : 150,
            left    : '23vw'
        }
    },
    llamanos: {
        position : 'absolute',
        top      : '2em',
        right    : '20vw'
    },
    llamanosImg: {
        width: 200
    },
    llamanosText: {
        color      : 'var(--main-color)',
        position   : 'absolute',
        top        : '18px',
        left       : '50px',
        fontFamily : 'var(--main-font-bold)',
        fontWeight : 600
    },
    drawer: {
        color: 'white'
    }
}))

export default function TopNav ({ scrollToRef, refs }) {
    const classes = useStyles()
    const [drawerOpen, setDrawer] = React.useState(false)
    const _handleToggleDrawer = React.useCallback(() => {
        setDrawer(!drawerOpen)
    }, [drawerOpen])

    return (
        <div className={ classes.root }>
            <AppBar position="fixed" className={ classes.topnav }>
                <Toolbar
                    disableGutters
                >
                    <Box className={ classes.logo } boxShadow={ 10 }>
                        <img width="100%" src={ logo } alt="albecar" title="albecar" />
                    </Box>
                    {/* <Box className={ classes.llamanos }>
                        <Link
                            className={ classes.boxPinkText }
                            href="tel:8113665730"
                        >
                            <img src={ llamanos } alt="llamaAlbecar" title="llamaAlbecar" className={ classes.llamanosImg } />
                            <Typography className={ classes.llamanosText }>(81) 1366 5730</Typography>
                        </Link>
                    </Box> */}
                    <Hidden smDown>
                        <Box mr={ 5 } display="flex" flexGrow={ 1 } alignItems="flex-end" justifyContent="flex-end" fontWeight="300">
                            <Box display="flex" alignItems="center" mr={ 5 }>
                                <Button className={ classes.menu } onClick={ () => scrollToRef(refs.acerca) }>
                                    Quiénes somos
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" mr={ 5 }>
                                <Button className={ classes.menu } onClick={ () => scrollToRef(refs.rutas, 80) }>
                                    Rutas
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" mr={ 5 }>
                                <Button className={ classes.menu } onClick={ () => scrollToRef(refs.servicios) }>
                                    Servicios
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" mr={ 5 }>
                                <Button className={ classes.menu } onClick={ () => scrollToRef(refs.clientes, 50) }>
                                    Clientes
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" mr={ 2 }>
                                <Button className={ classes.menu } onClick={ () => scrollToRef(refs.contacto) }>
                                    Contacto
                                </Button>
                            </Box>
                        </Box>
                    </Hidden>
                    <Hidden mdUp>
                        <Box
                            flexGrow={ 1 }
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <IconButton
                                className={ classes.drawer }
                                aria-label="menu"
                                disableRipple
                                onClick={ _handleToggleDrawer }
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <TemporaryDrawer
                            openDrawer={ drawerOpen }
                            toggleDrawer={ _handleToggleDrawer }
                            scrollToRef={ scrollToRef }
                            refs={ refs }
                        />
                    </Hidden>
                </Toolbar>
            </AppBar>
        </div>
    )
}

TopNav.propTypes = {
    scrollToRef : PropTypes.func.isRequired,
    refs        : PropTypes.object.isRequired
}
