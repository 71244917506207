import React from 'react'
import PropTypes from 'prop-types'
import LeftArrowIcon from '@material-ui/icons/NavigateBefore'
import IconButton from '@material-ui/core/IconButton'

const PrevArrow = ({
    classes,
    onClick
}) => (
    <IconButton
        className={ classes.arrowBtn }
        aria-label="navigate-left"
        onClick={ onClick }
        disableRipple
    >
        <LeftArrowIcon />
    </IconButton>
)

PrevArrow.propTypes = {
    classes : PropTypes.object.isRequired,
    onClick : PropTypes.func
}

PrevArrow.defaultProps = {
    onClick: undefined
}

export default PrevArrow
