import React from 'react'
import PropTypes from 'prop-types'
import RightArrowIcon from '@material-ui/icons/NavigateNext'
import IconButton from '@material-ui/core/IconButton'

const NextArrow = ({
    classes,
    onClick
}) => (
    <IconButton
        className={ classes.arrowBtn }
        aria-label="navigate-right"
        onClick={ onClick }
        disableRipple
    >
        <RightArrowIcon />
    </IconButton>
)

NextArrow.propTypes = {
    classes : PropTypes.object.isRequired,
    onClick : PropTypes.func
}

NextArrow.defaultProps = {
    onClick: undefined
}

export default NextArrow
