import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import Slider from 'react-slick'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import NextArrow from '../components/carousel/NextArrow'
import PrevArrow from '../components/carousel/PrevArrow'

import Heineken from '../assets/images/clientes/heineken.png'
import UniversalWipes from '../assets/images/clientes/universalWipes.png'
import Dicopamex from '../assets/images/clientes/dicopamex.png'
import Cecso from '../assets/images/clientes/cecso.png'
import PlasticTrends from '../assets/images/clientes/plasticTrends.png'
import Imbera from '../assets/images/clientes/imbera.png'
import Sts from '../assets/images/clientes/sts.png'

import Walmart from '../assets/images/clientes/walmart.png'
import Heb from '../assets/images/clientes/heb.png'
import Oxxo from '../assets/images/clientes/oxxo.png'
import Soriana from '../assets/images/clientes/soriana.png'
import FarmaciasGuadalajara from '../assets/images/clientes/farmaciasGuadalajara.png'
import FarmaciasBenavides from '../assets/images/clientes/farmaciasBenavides.png'

const useStyles = makeStyles(theme => ({
    title1: {
        color                          : 'var(--main-color)',
        fontFamily                     : 'var(--main-font-bold)',
        fontSize                       : '2.5rem',
        margin                         : '0.5em',
        textAlign                      : 'center',
        [theme.breakpoints.down('sm')] : {
            fontSize : '2rem',
            margin   : '1em 0.5em'
        }
    },
    slider: {
        display             : 'flex',
        alignItems          : 'center',
        '& > .slick-list'   : {
            flexGrow: 1
        }
    },
    slideContainer: {
        display         : 'grid',
        gridTemplate    : '"img" 1fr "desc" auto / 1fr'
    },
    slideImage: {
        gridArea    : 'img',
        '& img'     : {
            width                           : 'auto',
            height                          : 'auto',
            maxHeight                       : 200,
            maxWidth                        : 180,
            [theme.breakpoints.up('md')]    : {
                maxHeight   : 320,
                maxWidth    : 300
            },
            [theme.breakpoints.up('lg')]: {
                maxHeight   : 350,
                maxWidth    : 330
            },
            margin: 'auto'
        }
    },
    arrowBtn: {
        margin          : theme.spacing(1),
        color           : '#231f20 !imporant',
        '&:hover'       : {
            backgroundColor : 'unset',
            color           : 'var(--main-color)'
        }
    },
    prevArrow: {
        position : 'absolute',
        margin   : 'auto',
        height   : '100%',
        zIndex   : 99
    },
    nextArrow: {
        position : 'absolute',
        right    : 0,
        top      : 0,
        margin   : 'auto',
        height   : '100%',
        zIndex   : 99
    }
}))

export default function Clientes () {
    const classes = useStyles()
    const _theme = useTheme()

    const checkSm = useMediaQuery(_theme.breakpoints.up('sm'))
    const checkMd = useMediaQuery(_theme.breakpoints.up('1100'))
    const checkLg = useMediaQuery(_theme.breakpoints.up('lg'))
    const checkXL = useMediaQuery(_theme.breakpoints.up('1400'))

    let maxSlidesToShow = 1
    if (checkXL) {
        maxSlidesToShow = 4
    } else if (checkLg) {
        maxSlidesToShow = 3
    } else if (checkMd) {
        maxSlidesToShow = 3
    } else if (checkSm) {
        maxSlidesToShow = 2
    }

    const clientsArr = [{ src: Heineken, name: 'Heineken' }, { src: UniversalWipes, name: 'UniversalWipes' }, { src: Dicopamex, name: 'Dicopamex' }, { src: Cecso, name: 'Cecso' }, { src: PlasticTrends, name: 'PlasticTrends' }, { src: Imbera, name: 'Imbera' }, { src: Sts, name: 'Sts' }]

    const cedisArr = [{ src: Walmart, name: 'Walmart' }, { src: Heb, name: 'Heb' }, { src: Oxxo, name: 'Oxx' }, { src: Soriana, name: 'Soriana' }, { src: FarmaciasGuadalajara, name: 'FarmaciasGuadalajara' }, { src: FarmaciasBenavides, name: 'FarmaciasBenavides' }]

    return (
        <>
            <Box mt={ 7 }>
                <Typography className={ classes.title1 }>Nuestros clientes principales</Typography>
                <Slider
                    slidesToShow={ maxSlidesToShow }
                    slidesToScrol={ 1 }
                    nextArrow={ <NextArrow classes={ classes } /> }
                    prevArrow={ <PrevArrow classes={ classes } /> }
                    className={ classes.slider }
                    draggable
                >
                    {
                        clientsArr.map(cliente => (
                            <div key={ cliente.name }>
                                <div className={ classes.slideContainer }>
                                    <div className={ classes.slideImage }>
                                        <img src={ cliente.src } alt={ cliente.name } title={ cliente.name } />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </Box>
            <Box mt={ 7 } mb={ 7 }>
                <Typography className={ classes.title1 }>Nuestra experiencia en CEDIS</Typography>
                <Slider
                    slidesToShow={ maxSlidesToShow }
                    slidesToScrol={ 1 }
                    nextArrow={ <NextArrow classes={ classes } /> }
                    prevArrow={ <PrevArrow classes={ classes } /> }
                    className={ classes.slider }
                    draggable
                >
                    {
                        cedisArr.map(cedis => (
                            <div key={ cedis.name }>
                                <div className={ classes.slideContainer }>
                                    <div className={ classes.slideImage }>
                                        <img src={ cedis.src } alt={ cedis.name } title={ cedis.name } />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </Box>
        </>
    )
}
