import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core/'

import logo from '../assets/images/logo.png'

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor                : 'var(--white)',
        height                         : 'auto',
        minHeight                      : '100px',
        display                        : 'flex',
        flex                           : 1,
        justifyContent                 : 'flex-start',
        alignItems                     : 'center',
        [theme.breakpoints.down('sm')] : {
            height          : 50,
            justifyContent  : 'center'
        }
    },
    logo: {
        marginLeft                     : '5em',
        width                          : 250,
        padding                        : '1.5em',
        [theme.breakpoints.down('sm')] : {
            width  : 150,
            margin : 0
        }
    }
}))

export default function Footer () {
    const classes = useStyles()

    return (
        <Box className={ classes.footer }>
            <img className={ classes.logo } src={ logo } alt="albecar" title="albecar" />
        </Box>
    )
}
