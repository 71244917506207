/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core/'
import LazyHero from 'react-lazy-hero'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import TopNav from './TopNav'
import Footer from './Footer'
import BannerTrailer from '../assets/images/bannerTrailer.png'
import Trailer from '../assets/images/trailer.png'
import TrailerIcon from '../assets/images/trailerIcon.png'
import MapaIcon from '../assets/images/mapaIcon.png'
import BannerCarretera from '../assets/images/bannerCarreteraAzul.png'
import BannerTrailerAzul from '../assets/images/bannerTrailerAzul.png'
import MapaMexico from '../assets/images/mapaMexico.png'
import TopForm from '../components/TopForm'
import Clientes from './Clientes'
import BottomForm from '../components/BottomForm'

const useStyles = makeStyles(theme => ({
    container: {
        display         : 'flex',
        flexDirection   : 'column',
        flex            : 1,
        backgroundColor : 'var(--white)'
    },
    bannerTitle: {
        color                            : 'var(--white)',
        fontFamily                       : 'var(--main-font-bold)',
        fontSize                         : '5.5rem',
        margin                           : '0 1em',
        textAlign                        : 'left',
        lineHeight                       : 1.1,
        [theme.breakpoints.down('1800')] : {
            fontSize: '4rem'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize    : '1.5rem',
            textAlign   : 'center'
        },
        [theme.breakpoints.down('325')]: {
            fontSize    : '1rem',
            margin      : '0em 0.5em'
        }
    },
    bannerTitleSub: {
        color                            : 'var(--white)',
        fontFamily                       : 'var(--main-font)',
        fontSize                         : '4rem',
        lineHeight                       : 0.5,
        marginBottom                     : '1em',
        [theme.breakpoints.down('1800')] : {
            fontSize: '3.8rem'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3rem'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize    : '1.5rem',
            textAlign   : 'center'
        },
        [theme.breakpoints.down('325')]: {
            fontSize: '1rem'
        }
    },
    rowSection: {
        backgroundColor                : 'var(--white)',
        display                        : 'flex',
        flexDirection                  : 'column',
        flex                           : 1,
        justifyContent                 : 'center',
        alignItems                     : 'center',
        padding                        : '8em 10em',
        textAlign                      : 'center',
        [theme.breakpoints.down('sm')] : {
            padding: '2em'
        }
    },
    title1: {
        color                          : 'var(--main-color)',
        fontFamily                     : 'var(--main-font-bold)',
        fontSize                       : '2.5rem',
        margin                         : '0.5em',
        textAlign                      : 'center',
        [theme.breakpoints.down('sm')] : {
            fontSize   : '2rem',
            margin   : '1em 0'
        }
    },
    textBigGray: {
        color                          : 'var(--dark-gray)',
        fontFamily                     : 'var(--main-font)',
        fontSize                       : '2rem',
        textAlign                      : 'center',
        [theme.breakpoints.down('sm')] : {
            fontSize: '1.5rem'
        }
    },
    title1Gray: {
        color                          : 'var(--dark-gray)',
        fontFamily                     : 'var(--main-font-bold)',
        fontSize                       : '2.5rem',
        [theme.breakpoints.down('sm')] : {
            fontSize: '2rem'
        },
        [theme.breakpoints.down('325')]: {
            fontSize: '1.5rem'
        }
    },
    text2: {
        color                           : 'var(--dark-gray)',
        fontFamily                      : 'var(--main-font)',
        fontSize                        : '1.5rem',
        [theme.breakpoints.down('325')] : {
            fontSize: '1rem'
        }
    },
    solutions: {
        display                        : 'flex',
        flex                           : 1,
        flexDirection                  : 'column',
        justifyContent                 : 'center',
        alignItems                     : 'stretch',
        backgroundColor                : 'var(--light-gray)',
        padding                        : '5em',
        [theme.breakpoints.down('sm')] : {
            padding: '2em 1em'
        }
    },
    titleBox: {
        margin                         : '1vw 10vw',
        [theme.breakpoints.down('md')] : {
            margin: '1vw 5vw'
        }
    },
    trailerImage: {
        width   : '450',
        display : 'flex',
        flex    : 1
    },
    trailerTextBox: {
        display                        : 'flex',
        margin                         : '0 5em',
        flex                           : 0.7,
        flexDirection                  : 'column',
        [theme.breakpoints.down('sm')] : {
            margin  : '1em',
            flex    : 1
        }
    },
    subtitle: {
        color                          : 'var(--dark-gray)',
        fontFamily                     : 'var(--main-font-bold)',
        fontSize                       : '1.2rem',
        [theme.breakpoints.down('sm')] : {
            textAlign: 'center'
        }
    },
    text3: {
        color                          : 'var(--dark-gray)',
        fontFamily                     : 'var(--main-font)',
        fontSize                       : '1rem',
        [theme.breakpoints.down('sm')] : {
            textAlign: 'center'
        }
    },
    formBox: {
        height                                  : '50vh',
        width                                   : '30vw',
        [theme.breakpoints.down('md')] : {
            width: '45vw'
        },
        [theme.breakpoints.down('sm')]: {
            width: '80vw'
        }
    },
    formBoxHeader: {
        display                                 : 'flex',
        flex                                    : 1,
        justifyContent                          : 'center',
        alignItems                              : 'center',
        backgroundColor                         : 'var(--main-color)',
        height                                  : '80px',
        borderTopLeftRadius                     : 15,
        borderTopRightRadius                    : 15,
        width                                   : '30vw',
        [theme.breakpoints.down('md')] : {
            width: '45vw'
        },
        [theme.breakpoints.down('sm')]: {
            height  : '50px',
            width   : '80vw'
        },
        [theme.breakpoints.down('325')]: {
            height: '40px'
        }
    },
    formBoxBodyTransparent: {
        backgroundColor                         : 'rgba(255, 255, 255, 0.6)',
        height                                  : '65vh',
        width                                   : '30vw',
        zIndex                                  : 1,
        [theme.breakpoints.down('md')] : {
            width: '45vw'
        },
        [theme.breakpoints.down('sm')]: {
            width: '80vw'
        }
    },
    formBoxBody: {
        height                                  : '60vh',
        width                                   : '30vw',
        zIndex                                  : 2,
        position                                : 'relative',
        top                                     : '-62vh',
        [theme.breakpoints.down('md')] : {
            width: '45vw'
        },
        [theme.breakpoints.down('sm')]: {
            width   : '80vw',
            top     : '-65vh'
        }
    },
    formTitle: {
        color                           : 'var(--white)',
        fontFamily                      : 'var(--main-font)',
        fontSize                        : '1.8rem',
        [theme.breakpoints.down('325')] : {
            fontSize: '1.2rem'
        }
    },
    gracias1: {
        backgroundColor : 'rgba(255, 255, 255, 0.8)',
        padding         : theme.spacing(10)
    },
    graciasText1: {
        color           : 'var(--main-color)',
        fontSize        : '2.5rem',
        fontFamily      : 'var(--main-font)'
    },
    graciasText12: {
        color           : 'var(--main-color)',
        fontSize        : '2rem',
        fontFamily      : 'var(--main-font)'
    },
    trailerImg: {
        width                          : '30vw',
        [theme.breakpoints.down('sm')] : {
            width: '70vw'
        }
    },
    mapCombo: {
        position                       : 'relative',
        [theme.breakpoints.down('sm')] : {
            marginTop: '6em'
        }
    },
    mapBox: {
        position                       : 'absolute',
        right                          : '-3em',
        top                            : 0,
        [theme.breakpoints.down('md')] : {
            right: 0
        }
    },
    mapaMexico: {
        width                          : '61vw',
        [theme.breakpoints.down('md')] : {
            width: '80vw'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        }
    },
    mapBoxTxtBig: {
        fontFamily                     : 'var(--main-font-bold)',
        fontSize                       : '3vw',
        color                          : 'var(--white)',
        [theme.breakpoints.down('sm')] : {
            fontSize    : '1.5rem',
            marginTop   : '-90px'
        },
        [theme.breakpoints.down('325')]: {
            fontSize    : '1.5rem',
            marginTop   : '-170px'
        }
    },
    mapBoxTxtSmall: {
        fontFamily                     : 'var(--main-font)',
        fontSize                       : '1.3vw',
        color                          : 'var(--white)',
        textAlign                      : 'left',
        [theme.breakpoints.down('sm')] : {
            fontSize: '1rem'
        }
    },
    servicios: {
        display                        : 'flex',
        flex                           : '1',
        padding                        : '5em 7vw',
        flexDirection                  : 'column',
        alignItems                     : 'center',
        justifyContent                 : 'center',
        backgroundColor                : 'var(--light-gray)',
        [theme.breakpoints.down('sm')] : {
            padding: '2em'
        }
    },
    blueBox: {
        display                        : 'flex',
        flex                           : 1,
        flexDirection                  : 'column',
        justifyContent                 : 'center',
        alignItems                     : 'center',
        backgroundColor                : 'var(--main-color)',
        borderRadius                   : 70,
        margin                         : '1em',
        padding                        : '1.5em 5em',
        width                          : '30vw',
        height                         : '7.5vw',
        [theme.breakpoints.down('sm')] : {
            borderRadius    : 20,
            width           : '45vw',
            margin          : '.5em',
            height          : '25vw',
            padding         : '1.5em 1em'
        },
        [theme.breakpoints.down('325')]: {
            padding : '0.5em',
            height  : '27vw'
        }
    },
    blueBoxTitle: {
        color                          : 'var(--white)',
        fontSize                       : '2vw',
        fontFamily                     : 'var(--main-font-bold)',
        [theme.breakpoints.down('sm')] : {
            fontSize: '1.3rem'
        },
        [theme.breakpoints.down('325')]: {
            fontSize: '1rem'
        }
    },
    blueBoxText: {
        color                          : 'var(--white)',
        fontSize                       : '1.2vw',
        fontFamily                     : 'var(--main-font)',
        textAlign                      : 'center',
        [theme.breakpoints.down('sm')] : {
            fontSize: '.8rem'
        }
    },
    clientes: {
        backgroundColor: 'var(--white)'
    },
    bottomFormTitle: {
        fontFamily                     : 'var(--main-font)',
        color                          : 'var(--white)',
        fontSize                       : '3vw',
        margin                         : '0 4em',
        [theme.breakpoints.down('sm')] : {
            fontSize : '1.5rem',
            margin   : '0 1em'
        },
        [theme.breakpoints.down('325')]: {
            fontSize: '1rem'
        }
    },
    bottomFormTitleBold: {
        color                          : 'var(--white)',
        fontFamily                     : 'var(--main-font-bold)',
        fontSize                       : '3vw',
        [theme.breakpoints.down('sm')] : {
            fontSize: '1.5rem'
        },
        [theme.breakpoints.down('325')]: {
            fontSize: '1rem'
        }
    }
}))

export default function Landing () {
    const classes = useStyles()
    const [messageSent, setMessageSent] = React.useState(false)
    const [submittingForm, setSubmittingForm] = React.useState(false)
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [snackbarMessage, setSnackbarMessage] = React.useState('')
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const acerca = React.useRef(null)
    const servicios = React.useRef(null)
    const rutas = React.useRef(null)
    const clientes = React.useRef(null)
    const contacto = React.useRef(null)

    const _handleCloseSnackbar = React.useCallback(() => {
        setSnackbarOpen(false)
        setSnackbarMessage('')
    }, [])

    const scrollToRef = (ref, offset = 0) => {
        console.log(ref)
        if (ref) {
            const position = ref?.current?.offsetTop - offset
            window.scrollTo({ top: position, behavior: 'smooth' })
        }
    }

    async function _handleOnSubmit (values) {
        setSubmittingForm(true)
        try {
            await axios.post('https://albecarback.now.sh/', values)
                .then(res => {
                    // console.log(res)
                    if (res.data === 'Success') {
                        setSnackbarMessage('¡Mensaje Enviado!')
                        setSnackbarOpen(true)
                        setMessageSent(true)
                    } else {
                        setSnackbarMessage('Lo sentimos. No fue posible enviar tu mensaje.')
                        setSnackbarOpen(true)
                    }
                })
                .catch(() => {
                    setSnackbarMessage('Lo sentimos. No fue posible enviar tu mensaje.')
                    setSnackbarOpen(true)
                })
        } catch (error) {
            console.log(error)
            setSnackbarMessage('Lo sentimos. No fue posible enviar tu mensaje.')
            setSnackbarOpen(true)
        }
    }

    return (
        <>
            <CssBaseline />
            <Box className={ classes.container }>
                <TopNav scrollToRef={ scrollToRef } refs={{ acerca, servicios, rutas, clientes, contacto }} />
                <LazyHero
                    style={{ overflow: 'hidden' }}
                    imageSrc={ BannerTrailer }
                    opacity={ 0 }
                    minHeight="90vh"
                    parallaxOffset={ 200 }
                    transitionTimingFunction="ease-in-out"
                    isCentered
                    isFixed
                    transitionDuration={ 500 }
                >
                    <Box display="flex" flex={ 1 } flexDirection={ smallScreen ? 'column' : 'row' } alignItems="center" justifyContent="flex-start">
                        <Box display="flex" flex={ 1 } flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
                            <Typography className={ classes.bannerTitle }>SOLUCIONES DE TRANSPORTE TERRESTRE Y ALMACENAJE<Typography className={ classes.bannerTitleSub }>para tu empresa</Typography></Typography>
                        </Box>
                        <Box display="flex" flex={ 1 } flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
                            {
                                messageSent ? (
                                    <Box className={ classes.gracias1 }>
                                        <Typography className={ classes.graciasText1 }>Gracias por tu mensaje.</Typography>
                                        <Typography className={ classes.graciasText12 }>En breve nos pondremos en contacto contigo.</Typography>
                                    </Box>
                                ) : (
                                    <Box className={ classes.formBox }>
                                        <Box className={ classes.formBoxHeader }>
                                            <Typography className={ classes.formTitle }>COTIZA TU SERVICIO:</Typography>
                                        </Box>
                                        <Box className={ classes.formBoxBodyTransparent } />
                                        <Box className={ classes.formBoxBody }>
                                            <TopForm submittingForm={ submittingForm } _handleOnSubmit={ _handleOnSubmit } />
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                    </Box>
                </LazyHero>
                <Box className={ classes.rowSection }>
                    <Typography className={ classes.title1 }>Cotiza hoy tu servicio de transporte.</Typography>
                    <Typography className={ classes.textBigGray }>Contamos con servicio de primer nivel para transportar mercancía a nivel nacional o de un centro de distribución a otro.</Typography>
                </Box>
                <Box ref={ acerca } className={ classes.solutions }>
                    <Box className={ classes.titleBox }>
                        <Typography className={ classes.title1 }>Conoce nuestras soluciones de transporte terrestre y almacenaje que tenemos para ti.</Typography>
                    </Box>
                    <Box display="flex" flex={ 1 } flexWrap="wrap" alignItems="center" justifyContent="center">
                        <img className={ classes.trailerImg } src={ Trailer } alt="trailer" title="trailer" />
                        <Box className={ classes.trailerTextBox }>
                            <Typography className={ classes.title1Gray }>Transporte Terrestre</Typography>
                            <Typography className={ classes.text2 }>Contamos con el servicio puerta a puerta integrando los distintos medios de transporte con los que contamos:</Typography>
                            <ul className={ classes.text2 }>
                                <li>Unidad tráiler con caja seca 53´</li>
                                <li>Rabón 5 tons</li>
                                <li>Unidad 3.5 tons</li>
                            </ul>
                        </Box>
                    </Box>
                    <Box ml={ smallScreen ? 2 : 15 } mb={ 5 } display="flex" flex={ 1 } flexDirection="column" alignItems="flex-start" justifyContent="center">
                        <Typography className={ classes.title1Gray }>Almacenaje</Typography>
                        <Typography className={ classes.text2 }>Almacenamiento en bodega con circuito cerrado para los clientes que se interesen por el servicio de cross docking en nuestro almacén.</Typography>
                    </Box>
                    <Box display="flex" flex={ 1 } flexDirection="row" alignItems="center" justifyContent="space-between">
                        <Box display="flex" flex={ 1 } flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="center">
                            <img width={ smallScreen ? 110 : 150 } src={ TrailerIcon } alt="trailerIcon" title="trailerIcon" />
                            <Box m={ smallScreen ? 1 : 3 }>
                                <Typography className={ classes.subtitle }>Distribución directa</Typography>
                                <Typography className={ classes.text3 }>a tiendas, mayoristas, centro de distribución y rutas críticas.</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" flex={ 1 } flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="center">
                            <img width={ smallScreen ? 80 : 90 } src={ MapaIcon } alt="mapaIcon" title="mapaIcon" />
                            <Box m={ smallScreen ? 1 : 3 }>
                                <Typography className={ classes.subtitle }>Distribución regional y nacional</Typography>
                                <Typography className={ classes.text3 }>con unidades dedicadas.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <div ref={ rutas }>
                    <LazyHero
                        style={{ overflow: 'hidden' }}
                        imageSrc={ BannerCarretera }
                        opacity={ 0 }
                        minHeight={ smallScreen ? '75vh' : '90vh' }
                        parallaxOffset={ 0 }
                        transitionTimingFunction="ease-in-out"
                        isCentered
                        isFixed
                        transitionDuration={ 600 }
                    >
                        <Box className={ classes.mapCombo }>
                            <Box className={ classes.mapBox }>
                                <Typography className={ classes.mapBoxTxtBig }>Nuestras rutas principales son</Typography>
                                <Box ml={ smallScreen ? 10 : 0 } display="flex" flex={ 1 } flexDirection="row" alignItems="flex-start" justifyContent="center">
                                    <Box mr={ 2 }>
                                        <Typography className={ classes.mapBoxTxtSmall }>Monterrey</Typography>
                                        <Typography className={ classes.mapBoxTxtSmall }>Guadalajara</Typography>
                                        <Typography className={ classes.mapBoxTxtSmall }>León</Typography>
                                        <Typography className={ classes.mapBoxTxtSmall }>Querétaro</Typography>
                                    </Box>
                                    <Box ml={ 2 }>
                                        <Typography className={ classes.mapBoxTxtSmall }>Altamira</Typography>
                                        <Typography className={ classes.mapBoxTxtSmall }>Chihuahua</Typography>
                                        <Typography className={ classes.mapBoxTxtSmall }>Ciudad Juárez</Typography>
                                        <Typography className={ classes.mapBoxTxtSmall }>Hermosillo</Typography>
                                        <Typography className={ classes.mapBoxTxtSmall }>Tijuana</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <img className={ classes.mapaMexico } src={ MapaMexico } alt="mapaMexico" title="mapaMexico" />
                        </Box>
                    </LazyHero>
                </div>
                <Box ref={ servicios } className={ classes.servicios }>
                    <Typography className={ classes.title1 }>Siéntete seguro con nuestros servicios.</Typography>
                    <Typography className={ classes.textBigGray }>La calidad y servicio nos caracteriza. Contamos con altos estándares, aumento en capacidad de respuesta, administración de riesgos por traslados, y más. <span className={ classes.title1Gray }>Nuestros pilares de marca son:</span></Typography>
                    <Box mt={ smallScreen ? 1 : 4 } display="flex" flex={ 1 } flexDirection="row" alignItems="center" justifyContent="center">
                        <Box className={ classes.blueBox }>
                            <Typography className={ classes.blueBoxTitle }>FLEXIBILIDAD:</Typography>
                            <Typography className={ classes.blueBoxText }>Servicio adaptado a la necesidad del cliente</Typography>
                        </Box>
                        <Box className={ classes.blueBox }>
                            <Typography className={ classes.blueBoxTitle }>SERVICIO:</Typography>
                            <Typography className={ classes.blueBoxText }>Atención directa, 24/7 y sin intermediarios</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flex={ 1 } flexDirection="row" alignItems="center" justifyContent="center">
                        <Box className={ classes.blueBox }>
                            <Typography className={ classes.blueBoxTitle }>SEGURIDAD:</Typography>
                            <Typography className={ classes.blueBoxText }>Unidades aseguradas con GPS y sistema de rastreo vía APP</Typography>
                        </Box>
                        <Box className={ classes.blueBox }>
                            <Typography className={ classes.blueBoxTitle }>CONFIANZA:</Typography>
                            <Typography className={ classes.blueBoxText }>Menor al 0.5% de viajes con cita retrasada</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box ref={ clientes } className={ classes.clientes }>
                    <Clientes />
                </Box>
                <div ref={ contacto }>
                    <LazyHero
                        imageSrc={ BannerTrailerAzul }
                        opacity={ 0 }
                        minHeight="90vh"
                        parallaxOffset={ 0 }
                        transitionTimingFunction="ease-in-out"
                        isCentered
                        isFixed={ !smallScreen }
                        transitionDuration={ 500 }
                    >
                        <Box m={ 2 } display="flex" flex={ 1 } flexDirection="column" alignItems="center" justifyContent="flex-start">
                            <Typography className={ classes.bottomFormTitle }>
                                <span className={ classes.bottomFormTitleBold }>¡CONTÁCTANOS!</span> Somos los más eficaces en transporte terrestre y almacenaje.
                            </Typography>
                            <Box mt={ 5 } display="flex" flex={ 1 } flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
                                {
                                    messageSent ? (
                                        <Box className={ classes.gracias1 }>
                                            <Typography className={ classes.graciasText1 }>Gracias por tu mensaje.</Typography>
                                            <Typography className={ classes.graciasText12 }>En breve nos pondremos en contacto contigo.</Typography>
                                        </Box>
                                    ) : <BottomForm submittingForm={ submittingForm } _handleOnSubmit={ _handleOnSubmit } />
                                }
                            </Box>
                        </Box>
                    </LazyHero>
                </div>
                <Footer />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={ snackbarOpen }
                ContentProps={{
                    'aria-describedby': 'message-success'
                }}
                message={ <span id="message-id"> { snackbarMessage } </span> }
                action={ [
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={ _handleCloseSnackbar }
                    >
                        <CloseIcon className={ classes.icon } />
                    </IconButton>
                ] }
            />
        </>
    )
}
