import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

class Field extends Component {
    checkChange = e => {
        let val = e.target.value
        const { childProps } = this.props
        if (childProps.formatProps) {
            if (e.target.rawValue !== '') {
                switch (childProps.formatType) {
                    case 'simple':
                        val = e.target.rawValue
                        break
                    case 'fancy':
                        val = e.target.value
                        break
                    default:
                        val = parseFloat(e.target.rawValue)
                }
            } else {
                val = undefined
            }
        }
        return val
    }

    _handleKeyPress = e => {
        if (e.which === 13) {
            const { formProps } = this.props
            formProps.submitForm()
        }
    }

    _handleChange = e => {
        const { formProps, name, childProps: { onChangeFieldValue } } = this.props
        if (onChangeFieldValue) {
            onChangeFieldValue(e)
        }
        formProps.setFieldValue(name, this.checkChange(e))
    }

    render () {
        const {
            childProps: {
                formatProps,
                InputProps,
                multiline,
                rows,
                className
            },
            formProps,
            name,
            onBlur,
            placeholder,
            label,
            type,
            value,
            variant
        } = this.props
        const touched = formProps.touched[name],
            error = formProps.errors[name]
        let fieldValue = formProps.values[name] || ''
        if (formatProps) {
            InputProps.inputProps = {
                formatProps,
                ...InputProps.inputProps
            }
        }
        if (InputProps?.readOnly) {
            InputProps.inputProps = { onInput: this._handleChange }
            fieldValue = value?.view || ''
        }
        const InputLabelProps = InputProps.inputLabelProps
        return (
            <TextField
                className={ className }
                disabled={ formProps.isSubmitting }
                error={ touched && !!error }
                helperText={ touched && error }
                InputProps={ InputProps }
                label={ label === '' ? false : label }
                InputLabelProps={ InputLabelProps }
                name={ name }
                onBlur={ onBlur }
                onChange={ this._handleChange }
                onKeyPress={ this._handleKeyPress }
                placeholder={ placeholder }
                variant={ variant }
                type={ type }
                value={ fieldValue }
                multiline={ multiline }
                rows={ rows }
            />
        )
    }
}

export default Field

Field.propTypes = {
    childProps  : PropTypes.object.isRequired,
    formProps   : PropTypes.object.isRequired,
    name        : PropTypes.string.isRequired,
    onBlur      : PropTypes.func.isRequired,
    label       : PropTypes.string,
    placeholder : PropTypes.string,
    variant     : PropTypes.string,
    type        : PropTypes.string,
    value       : PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string
            ]),
            view: PropTypes.string
        })
    ])
}

Field.defaultProps = {
    label       : '',
    placeholder : '',
    type        : 'text',
    value       : undefined,
    variant     : 'outlined'
}
