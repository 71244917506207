/* src/lib/schema/store/contactForm.js */

import { object, string } from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const contactSchema = object({
    name: string()
        .trim()
        .default('')
        .required('Favor de ingresar un nombre'),
    email: string()
        .lowercase()
        .email('Favor de ingresar correo válido')
        .trim()
        .default('')
        .required('Favor de ingresar un correo'),
    phone: string()
        .trim()
        .matches(phoneRegExp, 'Favor de ingresar un teléfono válido a 10 digitos')
        .length(10, 'Favor de ingresar un teléfono válido a 10 digitos')
        .required('Favor de ingresar un teléfono'),
    message: string()
        .trim()
        .default('')
        .required('Favor de ingresar un mensaje')
})

export default contactSchema
